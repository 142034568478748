<template>
  <div class="mentors">
    <b-container>
      <b-row>
        <b-col
          class="mb-2 text-center"
          md="12"
          sm="12"
        >
          <h1 class="m-0">
            Наши менторы
          </h1>
        </b-col>
      </b-row>

      <swiper
        class="swiper-multiple"
        :options="swiperOptions"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      >
        <swiper-slide
          v-for="(data,index) in ourMentors"
          :key="index"
        >

          <b-card
            :img-src="data.photo ? 'https://edu.startupchoikhona.tj/backend/'+data.photo : require('@/assets/images/user/noPhoto.jpeg')"
            img-alt=""
            img-top
            :title="data.name"
            :sub-title="data.organization"
            class="h-100"
          />

        </swiper-slide>

        <!-- Add Arrows -->
        <div
          slot="button-next"
          class="swiper-button-next"
        />
        <div
          slot="button-prev"
          class="swiper-button-prev"
        />
      </swiper>

    </b-container>
  </div>
</template>

<script>

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import {
  BContainer, BRow, BCol, BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      /* eslint-disable global-require */
      ourMentors: [
      ],
      /* eslint-disable global-require */
      swiperOptions: {
        slidesPerView: 4,
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          1024: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          0: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
        },
      },
    }
  },
  created() {
    this.$http.get('https://edu.startupchoikhona.tj/backend/api/website/our-mentors', {})
      .then(response => {
        this.ourMentors = response.data.data
      })
  },
}
</script>

<style>
/*.mentors .swiper-wrapper {*/
/*  justify-content: center !important;*/
/*}*/

.mentors .card-body{
  min-height: 180px;
}

</style>
