<template>
  <div>
    <slider/>
    <about id="about" class="py-4"/>
    <mentors id="mentors" class="py-4"/>
    <courses id="courses" class="py-4"/>
    <news id="news" class="py-4"/>
    <services id="services" class="py-4"/>
    <reviews id="reviews" class="py-4"/>
    <about-video id="about-video" class="py-4"/>
    <subscription id="subscription" class="py-4"/>
  </div>
</template>

<script>

import Slider from './partials/Slider.vue'
import About from './partials/About.vue'
import Mentors from './partials/Mentors.vue'
import Courses from './partials/Courses.vue'
import News from './partials/News.vue'
import Services from './partials/Services.vue'
import Reviews from './partials/Reviews.vue'
import AboutVideo from './partials/AboutVideo.vue'
import Subscription from './partials/Subscription.vue'

export default {
  components: {
    Subscription,
    AboutVideo,
    Slider,
    About,
    Mentors,
    Courses,
    News,
    Services,
    Reviews,
  },
  created() {
  },
}
</script>

<style>

</style>
