<template>
  <swiper
    class="slider swiper-paginations"
    :options="swiperOptions"
    autoplay="1000"
    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
  >

    <swiper-slide
      v-for="(data,index) in sliders"
      :key="index"
      style="z-index: -2;"
    >
      <div class="slider-main-content">
        <div class="container">
          <div
            id="slider-content"
            class="slider-blur col-lg-8  col-12"
          >

            <div class="title-sm">
              {{ data.title }}
            </div>

            <h2 id="slide-title">
              {{ data.subtitle }}
            </h2>

            <div
              id="slide-status"
              class="slider-text-h2"
              v-html="data.description "
            >
            </div>

            <div class="input-group search mt-4 w-100 w-md-75" v-if="data.is_search === 1">
              <input
                class="form-control mr-1"
                type="text"
                placeholder="Чему ты хочешь научиться?"
              >
              <div class="input-group-append">
                <button
                  type="button"
                  class="btn px-1 text-uppercase"
                >
                  <feather-icon
                    class=""
                    icon="SearchIcon"
                  />
                  Поиск
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
      <b-img
        class="h-100 w-100"
        style="object-fit: cover;"
        :src="data.image ? 'https://edu.startupchoikhona.tj/backend/'+data.image : ''"
        fluid
      />
    </swiper-slide>

    <div
      slot="pagination"
      class="swiper-pagination"
    />
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { BImg } from 'bootstrap-vue'
import 'swiper/css/swiper.css'
import '@/assets/css/slider.css'

export default {
  components: {
    Swiper,
    SwiperSlide,
    BImg,
  },
  data() {
    return {
      sliders: [],
      swiperData: [
        { img: require('@/assets/images/banner/img.png') },
        { img: require('@/assets/images/banner/img_1.png') },
        { img: require('@/assets/images/banner/img_2.png') },
        { img: require('@/assets/images/banner/img_3.png') },
      ],
      swiperOptions: {
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
    }
  },
  created() {
    this.$http.get('https://edu.startupchoikhona.tj/backend/api/website/sliders', {})
      .then(response => {
        this.sliders = response.data.data
      })
  },
}
</script>

<style>

</style>
