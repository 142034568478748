<template>
  <div class="reviews">
    <b-container>
      <b-row>
        <b-col
          class="mb-4 text-center"
          md="12"
          sm="12"
        >
          <h1 class="m-0">
            Отзывы
          </h1>
        </b-col>
      </b-row>

      <swiper
        class="swiper-multiple"
        :options="swiperOptions"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      >
        <swiper-slide
          v-for="(data,index) in testimonials"
          :key="index"
        >

          <b-card
            :img-src="data.image ? 'https://edu.startupchoikhona.tj/backend'+data.image : require('@/assets/images/user/noPhoto.jpeg')"
            img-alt=""
            img-top
            img-rounded-circle
            :title="data.author"
            :sub-title="data.profession"
          >
            <b-card-text>
              <b-form-rating
                no-border
                :value="data.star"
                inline
                variant="warning"
                readonly
              />
            </b-card-text>
            <b-card-text>
              {{ data.body }}
            </b-card-text>
          </b-card>
        </swiper-slide>

      </swiper>

    </b-container>
  </div>
</template>

<script>

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import {
  BContainer, BRow, BCol, BCard, BCardText, BFormRating,
} from 'bootstrap-vue'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormRating,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      /* eslint-disable global-require */
      testimonials: [],
      /* eslint-disable global-require */
      swiperOptions: {
        slidesPerView: 4,
        spaceBetween: 30,
        autoHeight: true,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          0: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
        },
      },
    }
  },
  created() {
    this.$http.get('https://edu.startupchoikhona.tj/backend/api/website/testimonials', {})
      .then(response => {
        this.testimonials = response.data.data
      })
  },
}
</script>

<style>

</style>
