<template>
  <div class="news">
    <b-container>
      <b-row>
        <b-col
          class="mb-2 text-center"
          md="12"
          sm="12"
        >
          <h1 class="m-0">
            Новости
          </h1>
        </b-col>
      </b-row>

      <swiper
        class="swiper-multiple"
        :options="swiperOptions"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      >
        <swiper-slide
          v-for="(data,index) in blogs"
          :key="index"
        >
          <b-link :to="{ name: 'news-details', params: { id: data.id } }">
            <b-card
              class="box-shadow-0"
              :img-src="data.image ? 'https://edu.startupchoikhona.tj/backend/'+data.image : require('@/assets/images/blogs/noPhotoBlogs.jpeg')"
              img-alt=""
              img-top
              :title="data.title"
            >
              <span class="date">
                <feather-icon icon="CalendarIcon" />
                {{ data.updated_at }}
              </span>

              <b-card-text>
                {{ data.short_description }}
              </b-card-text>
              <hr>
              <b-card-text class="d-flex justify-content-between">
                <span>
                  <feather-icon
                    class=""
                    icon="UserIcon"
                  />
                  {{ data.author.name }}
                </span>

                <span>
                  <feather-icon
                    class=""
                    icon="MessageSquareIcon"
                  />
                  0
                </span>
              </b-card-text>
            </b-card>
          </b-link>
        </swiper-slide>

        <!-- Add Arrows -->
        <div
          slot="button-next"
          class="swiper-button-next"
        />
        <div
          slot="button-prev"
          class="swiper-button-prev"
        />
      </swiper>

    </b-container>
  </div>
</template>

<script>

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import {
  BLink, BContainer, BRow, BCol, BCard, BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BContainer,
    BRow,
    BCol,
    BCard,
    BCardText,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      /* eslint-disable global-require */
      blogs: [
      ],
      /* eslint-disable global-require */
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          0: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
        },
      },
    }
  },
  created() {
    this.$http.get('https://edu.startupchoikhona.tj/backend/api/website/blogs', {})
      .then(response => {
        this.blogs = response.data.data
      })
  },
}
</script>

<style>
/*.news .swiper-wrapper {*/
/*  justify-content: center !important;*/
/*}*/
</style>
