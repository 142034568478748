<template>
  <div class="about bg-light-primary">
    <b-container>
      <b-row>
        <b-col
            class="mb-2 text-justify"
            md="12"
            sm="12"
        >
          <h1 class="m-0 text-center">
            О проекте
          </h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col
            class="mb-2 text-center"
            md="12"
            sm="12"
        >
          <p class="info-text text-justify line-height-condensed" v-html="this.about">
          </p>
        </b-col>
      </b-row>
    </b-container>
    <created-for />
  </div>
</template>

<script>
import { BContainer, BRow, BCol } from 'bootstrap-vue'
import CreatedFor from './CreatedFor.vue'

export default {
  components: {
    CreatedFor,
    BContainer,
    BRow,
    BCol,
  },
  data() {
    return {
      about: '',
    }
  },
  created() {
    this.$http.get('https://edu.startupchoikhona.tj/backend/api/website/about-project', {})
      .then(response => {
        this.about = response.data.data.about
      })
  },
}
</script>

<style>

</style>
