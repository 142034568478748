<template>
  <div class="subscription">
    <b-container>
      <b-row>
        <b-col md="12" sm="12">
          <div class="subscription-content">
            <b-row class="align-items-center text-center text-sm-left ">
              <b-col md="6" sm="12">
                <h1 class="text-white font-large-1">Присоединяйтесь к нам сейчас</h1>
              </b-col>
              <b-col md="6" sm="12">
                <b-link :to="{name:'register'}" type="button" class="btn shadow-sm btn-gradient-primary btn-custom-subscription btn-lg float-sm-left float-lg-right px-1 text-uppercase">
                  Зарегистрироваться
                </b-link>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

import { BContainer, BRow, BCol, BLink } from 'bootstrap-vue'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BLink,
  },
  data() {
    return {}
  },
}
</script>

<style scoped>
.btn-custom-subscription {
  background-image: linear-gradient(47deg, #5b96cd, #75abdb);
}
</style>
