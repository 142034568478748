<template>
  <div class="services">
    <div class="created-for">
      <b-container>

        <b-row>
          <b-col
            class="mb-2 text-center"
            md="12"
            sm="12"
          >
            <h1 class="m-0">
              Услуги
            </h1>
          </b-col>
        </b-row>

        <b-row>

          <b-col
            class="mb-2"
            md="6"
            sm="12"
            v-for="(data, index) in services"
            :key="index"
          >
            <div class="card bg-light-primary p-2 align-items-center justify-content-start ">
              <span class="d-inline-block mr-3">
                <feather-icon
                  class="font-large-3"
                  icon="CheckCircleIcon"
                />
              </span>

              <span class="text-left d-inline-block">
                <h1 class="m-0">{{ data.name }}</h1>
              </span>

            </div>
          </b-col>

        </b-row>
      </b-container>
    </div>

  </div>
</template>

<script>

import { BContainer, BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
  },
  data() {
    return {
      services: [],
    }
  },
  created() {
    this.$http.get('https://edu.startupchoikhona.tj/backend/api/website/services', {})
      .then(response => {
        this.services = response.data.data
      })
  },
}
</script>

<style>

</style>
