<template>
  <b-container class="about-video">
    <b-row>
      <b-col md="12" sm="12">
        <div class="position-relative">
          <div class="about-video-mask"></div>
          <b-container
              class="video bg-primary d-flex flex-column align-items-center justify-content-center position-relative"
              :style="{backgroundImage: 'url(' + require('@/assets/images/landing/aboutVideo.png') + ')',}"
          >
            <b-link class="play-btn mb-4 d-flex align-items-center justify-content-center position-relative" @click="modalShow = !modalShow">
              <feather-icon class="text-dark font-large-2" icon="PlayIcon"/>
            </b-link>

            <div class="text-white mt-5 text-center">
              <h2 class="text-white font-weight-bolder">Начни учиться где угодно и когда угодно...</h2>
<!--              <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>-->
            </div>
          </b-container>
        </div>
      </b-col>
    </b-row>
    <b-modal
        id="modal-lg"
        size="lg"
        centered
        ok-only
        @click="modalShow = !modalShow"
        ok-title="Закрыть"
        v-model="modalShow"
    >
      <video src="https://edu.startupchoikhona.tj/backend/uploader/6. Ознакомительное видео о самом проекте Стартап Чойхона.mp4" class="w-100 h-100" controls autoplay v-if="modalShow"></video>
    </b-modal>
  </b-container>
</template>

<script>

import { BContainer, BRow, BCol, BLink, BModal, BCardText, } from 'bootstrap-vue'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BLink,
    BModal,
    BCardText,
  },
  data() {
    return {
      modalShow: false,
    }
  },
}
</script>

<style>

</style>
