<template>
  <div class="section-created-for mt-5">
    <div class="created-for">
      <b-container>

        <b-row>
          <b-col
            class="mb-2 text-center"
            md="12"
            sm="12"
          >
            <h1 class="m-0">
              Для кого создан проект?
            </h1>
          </b-col>
        </b-row>

        <b-row>

          <b-col
            class="mb-2"
            lg="4"
            md="6"
            sm="12"
            v-for="(data, index) in created_projects"
            :key="index"
          >
            <div class="card bg-primary p-2 align-items-center ">
              <span class="w-75 d-inline-block">
                <h3 class="text-white m-0 font-weight-light">{{ data.title }}</h3>
              </span>
              <span class="w-25 d-inline-block text-right">
                <feather-icon
                  class="text-white font-large-1"
                  icon="CheckCircleIcon"
                />
              </span>
            </div>
          </b-col>

        </b-row>
      </b-container>
    </div>

  </div>
</template>

<script>

import { BContainer, BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
  },
  data() {
    return {
      created_projects: [],
    }
  },
  created() {
    this.$http.get('https://edu.startupchoikhona.tj/backend/api/website/created-project', {})
      .then(response => {
        this.created_projects = response.data.data.created_projects
      })
  },
}
</script>

<style>

</style>
