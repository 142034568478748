<template>
  <div class="courses bg-light-primary">
    <b-container>
      <b-row>
        <b-col class="mb-2 text-center" md="12" sm="12">
          <h1 class="m-0">Курсы</h1>
        </b-col>
      </b-row>

      <swiper
          class="swiper-multiple"
          :options="swiperOptions"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      >
        <swiper-slide
            v-for="(course,index) in courses"
            :key="index"
        >
          <b-card
              class="box-shadow-1 h-100"
              :img-src="course.image ? `https://edu.startupchoikhona.tj/backend/${course.image}` : require('@/assets/images/blogs/noPhotoBlogs.jpeg')"
              img-alt=""
              img-top
          >
            <b-link :to="`/course-details/${course.id}/fullscreen`"><span class="play-course"><h3 class="w-100 h-100 text-white bold">Начать</h3></span></b-link>
            <b-link :to="{ name: 'course-details', params: { id: course.id } }">
              <b-card-text class="h3 font-weight-bold pt-1">
                {{course.title}}
              </b-card-text>
              <b-card-text>
                <small>
                  <span>Ментор: </span>
                  <span>{{ course.teacher.name }}</span>
                </small>
              </b-card-text>
              <b-card-text class="d-flex align-items-center">
            </span>

                <span>
              <ul class="unstyled-list list-inline">
                <li
                    v-for="star in 5"
                    :key="star"
                    class="ratings-list-item"
                    :class="{'ml-25': star-1}"
                >
                  <feather-icon
                      icon="StarIcon"
                      size="16"
                      :class="[{'fill-current': star <= course.rating}, star <= course.rating ? 'text-warning' : 'text-muted']"
                  />
                </li>
              </ul>
            </span>
              </b-card-text>
            </b-link>
          </b-card>
        </swiper-slide>

        <!-- Add Arrows -->
        <div
            slot="button-next"
            class="swiper-button-next"
        />
        <div
            slot="button-prev"
            class="swiper-button-prev"
        />
      </swiper>

    </b-container>
  </div>
</template>

<script>

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { BLink, BContainer, BRow, BCol, BCard, BCardText, } from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BContainer,
    BRow,
    BCol,
    BCard,
    BCardText,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      /* eslint-disable global-require */
      swiperData: [
        { img: require('@/assets/images/course/image/course2.png') },
        { img: require('@/assets/images/course/image/course3.png') },
        { img: require('@/assets/images/course/image/course4.png') },
        { img: require('@/assets/images/course/image/course1.png') },
        { img: require('@/assets/images/course/image/1.png') },
      ],
      /* eslint-disable global-require */
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          0: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
        },
      },
      courses: [],
    }
  },
  created() {
    this.fetchShopProducts()
  },
  methods: {
    view(id) {
      this.$router.push({
        name: 'course-details',
        params: { id },
      })
    },
    fetchShopProducts() {
      this.$http.get('https://edu.startupchoikhona.tj/backend/api/website/courses', {
        length: -1,
      })
          .then(response => {
            const { data } = response.data
            this.courses = data.items
          })
    },
  },
}
</script>

<style>
.courses .swiper-wrapper {
  justify-content: center !important;
}
.courses .play-course {
  cursor: pointer !important;
  clear: both !important;
  margin: auto !important;
  border-radius: 10% !important;
  box-shadow: 0 3px 0 #259cff, 0 3px 10px rgb(11 131 229), 0 0px 10px rgb(10 134 236), 0 0px 4px rgb(0 0 0 / 35%), 0 5px 20px rgb(9 121 214), 0 15px 40px rgb(49 140 215), inset 0 0 15px rgb(255 255 255 / 5%) !important;
}

.courses .play-course {
  width: 134px !important;
  height: 46px !important;
  position: absolute !important;
  display: block !important;
  top: -32px !important;
  overflow: hidden !important;
}

.courses .play-course h3 {
  left: 21% !important;
  top: 24% !important;
  position: relative !important;
}

.courses .play-course:after {
  background-image: unset !important;
}
</style>
